@import 'variables';
@import '~bootstrap/scss/variables';

a {
  color: $theme-color-primary;
  text-decoration: none;
  &:hover {
    color: darken($theme-color-primary, 5%);
  }
}

.text-lighten {
  opacity: 0.2;
}

.ng-invalid,
.ng-invalid > .ng-select-container {
  border-color: red !important;
  box-shadow: none;
}

.big-ng-select > .ng-select-container {
  height: 3.7em !important;
  & .ng-value-container .ng-input>input {
    margin-top: 0.5em;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hide-input-arrows::-webkit-outer-spin-button,
.hide-input-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hide-input-arrows[type='number'] {
  -moz-appearance: textfield;
}

@include media-breakpoint-down(sm) {

  .nav.tab-xs-100 .nav-item {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;

    a.active {
      border-bottom-color: $border-color;
    }
  }

}


.mt-n1px {
  margin-top: -1px;
}

.b-dashed {
  border-top: 1px dashed $border-color;
}
