@import 'variables';
@import '~bootstrap/scss/variables';

table.no-border-bottom-0 tbody tr:last-child td {
  border-bottom-width: 0;
}

.table-row-active {
  color: darken($theme-color-primary, 5%);
  background-color: lighten($primary, 38%);
}

.table-hover > tbody > tr.table-row-active:hover > * {
  color: inherit;
}

.table > :not(:first-child) {
  border-top-width: 0px;
}

.table > thead > tr:first-child th,
.table > thead th {
  border-bottom-width: 2px;
  border-bottom-color: darken($border-color, 12%);
}

.row-b-0 td {
  border-bottom: 0;
}
